import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { SharedModule } from '../../../shared/shared.module';
import { BetaHelpContentComponent } from './components/beta-help-content/beta-help-content.component';
import { HelpScreenComponent } from './screens/help-screen.component';

@NgModule({
  declarations: [HelpScreenComponent, BetaHelpContentComponent],
  imports: [CommonModule, SharedModule, HeaderComponent],
  exports: [BetaHelpContentComponent],
})
export class HelpModule {}
