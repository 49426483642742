import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationOutletComponent } from './navigation-outlet.component';
import { SharedModule } from '@suvo-bi-lib';

@NgModule({
  declarations: [NavigationOutletComponent],
  imports: [CommonModule, SharedModule],
  exports: [NavigationOutletComponent],
})
export class NavigationOutletModule {}
