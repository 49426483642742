import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { LoginScreenComponent } from './screens/login/login-screen.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [LoginScreenComponent],
  imports: [CommonModule, SharedModule],
})
export class PublicModule {}
