import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsScreenComponent } from './screens/notifications-screen/notifications-screen.component';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { SharedModule } from '../../../shared/shared.module';
import { NotificationsService } from './notifications.service';
import { NotificationComponent } from './components/notification/notification.component';

@NgModule({
  declarations: [NotificationsScreenComponent, NotificationComponent],
  imports: [CommonModule, SharedModule, HeaderComponent],
  providers: [NotificationsService],
})
export class NotificationsModule {}
