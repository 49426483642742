import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DashboardsModule, DataSourceModule, SmartGridModule } from '@suvo-bi-lib';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { ReportsComponentsModule } from './reports-components.module';
import { ReportsScreenComponent } from './screens/reports-screen/reports-screen.component';

@NgModule({
  imports: [
    CommonModule,
    DashboardsModule.forRoot({
      dataServiceApi: environment.cprServiceApi,
    }),
    DataSourceModule.forRoot({
      dataServiceApi: environment.cprServiceApi,
    }),
    SmartGridModule,
    ReportsComponentsModule,
    HeaderComponent,
  ],
  declarations: [ReportsScreenComponent],
})
export class ReportsModule {}
