import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TourModule } from '@suvo-bi-lib';
import { SharedModule } from '../../shared/shared.module';
import { AppOutletModule } from './app-outlet/app-outlet.module';
import { CprsModule } from './cprs/cprs.module';
import { ExportModule } from './export/export.module';
import { HelpModule } from './help/help.module';
import { KnowledgeBaseModule } from './knowledge-base/knowledge-base.module';
import { MicroscopeModule } from './microscopes/microscope.module';
import { NotificationsModule } from './notifications/notifications.module';
import { OverviewModule } from './overview/overview.module';
import { PrivateComponent } from './private.component';
import { ReportsModule } from './reports/reports.module';
import { RouteModule } from './routes/route.module';
import { SamplesModule } from './samples/samples.module';
import { ShipsModule } from './ships/ships.module';
import { TourDialogComponent } from './tour-dialog/tour-dialog.component';
import { TowsModule } from './tows/tows.module';
import { UsersModule } from './users/users.module';
import { AuditModule } from './audit/audit.module';

@NgModule({
  declarations: [PrivateComponent, TourDialogComponent],
  imports: [
    // Angular
    CommonModule,

    // Frontend lib
    TourModule,

    // Shared
    SharedModule,

    // Feature
    AppOutletModule,
    AuditModule,
    CprsModule,
    ExportModule,
    HelpModule,
    KnowledgeBaseModule,
    MicroscopeModule,
    NotificationsModule,
    OverviewModule,
    ReportsModule,
    RouteModule,
    SamplesModule,
    ShipsModule,
    TowsModule,
    UsersModule,
    ReportsModule,
  ],
})
export class PrivateModule {}
