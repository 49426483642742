import { Component, Input, OnDestroy } from '@angular/core';
import { ProgressBarMode } from '@angular/material/progress-bar';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';

/** @author Aidan */
@Component({
  selector: 'app-is-navigating',
  templateUrl: './is-navigating.component.html',
  styleUrls: ['./is-navigating.component.scss'],
})
export class IsNavigatingComponent implements OnDestroy {
  /**
   * Determinate mode behaviour increases progress with each router event.
   * Indeterminate mode shows a repetitive animation and may be more suitable
   * if progress hangs while awaiting network requests in resolvers, for example.
   */
  @Input() readonly mode: ProgressBarMode = 'determinate';

  /**
   * Transition duration mostly affects fade in/out.
   */
  @Input() readonly transitionDuration = 100;

  /**
   * Milliseconds to delay the progress bar from appearing.
   * It should only show when navigation is genuinely taking some time and only to show that things are progressing.
   * If it appears too often it makes the app *feel* slower, even if it is not.
   */
  @Input() private readonly debounce = 200;

  private $: Subscription;
  private inProgress = false;
  hidden = true;
  progress = 100;

  constructor(router: Router) {
    this.$ = router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart:
          this.start();
          break;
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError:
          this.stop();
          break;
        default:
          this.addProgress();
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.$.unsubscribe();
  }

  private start() {
    this.progress = 0;
    this.hidden = true;
    this.inProgress = true;
    setTimeout(() => this.inProgress && (this.hidden = false), this.debounce);
  }

  private stop() {
    this.progress = 100;
    this.hidden = true;
    this.inProgress = false;
  }

  private addProgress() {
    this.progress = Math.min(100, (this.progress += 5));
  }
}
