import { FilterConditionType } from '@suvo-bi-lib';
import { IFiltersSections } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/filters/interfaces/sections/filters-sections.interface';

export const SHIPS_FILTERS_SECTIONS: IFiltersSections = {
  name: 'ships',
  shortCode: 'ships',
  sections: [
    {
      options: [
        {
          filterMethod: 'keywords',
          filterPropertyKeys: ['search'],
          filterMethodConfig: {
            useForSearch: true,
            title: 'Search',
            label: 'By name',
          },
        },
      ],
    },
  ],
};
