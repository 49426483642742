import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FiltersModule } from '@suvo-bi-lib';
import { TablesModule } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/tables.module';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { SharedModule } from '../../../shared/shared.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { ShipsTableComponent } from './components/ships-table/ships-table.component';
import { ShipResolver } from './resolver/ship.resolver';
import { ShipEditScreenComponent } from './screens/edit/ship-edit-screen.component';
import { ShipTableScreenComponent } from './screens/table/ship-table-screen.component';
import { ShipsService } from './service/ships.service';
import { ShipRoutingModule } from './ship-routing.module';

@NgModule({
  declarations: [ShipsTableComponent, ShipTableScreenComponent, ShipEditScreenComponent],
  providers: [ShipsService, ShipResolver],
  imports: [
    CommonModule,
    SharedModule,
    TablesModule,
    ShipRoutingModule,
    PermissionsModule,
    FiltersModule,
    HeaderComponent,
  ],
})
export class ShipsModule {}
