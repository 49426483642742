import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TablesModule } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/tables.module';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { SharedModule } from '../../../shared/shared.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { RoutesTableComponent } from './components/routes-table/routes-table.component';
import { RouteResolver } from './resolver/route.resolver';
import { RouteTableScreenComponent } from './screens/table/route-table-screen.component';
import { RouteEditScreenComponent } from './screens/edit/route-edit-screen.component';
import { RoutesService } from './service/routes.service';
import { RouteRoutingModule } from './route-routing.module';
import { ReferencesModule } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/references/references.module';

@NgModule({
  declarations: [RoutesTableComponent, RouteTableScreenComponent, RouteEditScreenComponent],
  providers: [RoutesService, RouteResolver],
  imports: [
    CommonModule,
    SharedModule,
    TablesModule,
    RouteRoutingModule,
    PermissionsModule,
    ReferencesModule,
    HeaderComponent,
  ],
})
export class RouteModule {}
