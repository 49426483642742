import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FiltersModule, TablesModule } from '@suvo-bi-lib';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { SharedModule } from '../../../shared/shared.module';
import { UsersModule } from '../users/users.module';
import { AuditTableComponent } from './components/audit-table/audit-table.component';
import { AuditListScreenComponent } from './screens/list/audit-list-screen.component';
import { AuditTableService } from './services/audit-table.service';

@NgModule({
  imports: [CommonModule, SharedModule, UsersModule, FiltersModule, TablesModule, HeaderComponent],
  declarations: [AuditListScreenComponent, AuditTableComponent],
  providers: [AuditTableService],
})
export class AuditModule {}
