import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BreadcrumbsModule } from '@suvo-bi-core';
import { DataSourceFavouritesModule, FiltersModule, ListNavigationModule } from '@suvo-bi-lib';
import { SubscriptionModule } from '@suvo-bi-users';
import { SharedModule } from '../../../shared/shared.module';
import { FooterComponent } from './components/footer/footer.component';
import { MainOutletComponent } from './components/main-outlet/main-outlet.component';
import { MainSidenavComponent } from './components/main-sidenav/main-sidenav.component';
import { NavigationOutletModule } from './components/navigation-outlet/navigation-outlet.module';
import { AccountSidenavWidgetComponent } from './components/account-sidenav-widget/account-sidenav-widget.component';
import { environment } from 'apps/mba-cpr-survey-portal/src/environments/environment';
import { FILTERS_SECTIONS } from '../filters/constants/filters-sections.const';

@NgModule({
  declarations: [
    MainOutletComponent,
    MainSidenavComponent,
    FooterComponent,
    AccountSidenavWidgetComponent,
  ],
  imports: [
    CommonModule,
    DataSourceFavouritesModule,
    SharedModule,
    FiltersModule.forRoot({
      filtersSections: FILTERS_SECTIONS,
      dataServiceApi: environment.cprServiceApi,
      reverseUrlParts: true,
    }),
    ListNavigationModule.forRoot({
      showAskAQuestion: false,
    }),
    BreadcrumbsModule,
    NavigationOutletModule,
    SubscriptionModule,
  ],
  exports: [MainOutletComponent],
})
export class AppOutletModule {}
