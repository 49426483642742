import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  trackingId;

  public init(trackingId: string): void {
    this.trackingId = trackingId;

    console.log('INIT GOOGLE ANALYTICS ', trackingId);
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
    script.async = true;

    script.onload = () => {
      (<any>window).dataLayer = (<any>window).dataLayer || [];

      this.gtag('js', new Date());

      this.pageView();
    };

    document.head.appendChild(script);
  }

  gtag(...any) {
    (<any>window).dataLayer?.push(arguments);
  }

  pageView(options?) {
    if (options) {
      this.gtag('config', this.trackingId, options);
    } else {
      this.gtag('config', this.trackingId);
    }
  }
}
