import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AngularFireModule, FIREBASE_OPTIONS } from '@angular/fire/compat';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { CommonComponentsModule } from '@suvo-bi-core';
import { AppConfigModule } from '@suvo-bi-lib';
import { NgcCookieConsentConfig, NgcCookieConsentModule } from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ResponseInterceptor } from './features/interceptors/response.interceptor';
import { PrivateModule } from './features/private/private.module';
import { PublicModule } from './features/public/public.module';
import { IsNavigatingComponent } from './shared/components/is-navigating/is-navigating.component';

const cookieConfig: NgcCookieConsentConfig = {
  enabled: false,
  cookie: {
    domain: environment.publicUrl,
    secure: false,
    expiryDays: 365,
    path: 'emt-consent',
    name: 'emt-consent',
  },
  content: {
    href: '/privacy-policy',
  },
  palette: {
    popup: {
      background: '#5cb3a7',
      text: '#fff',
    },
    button: {
      background: '#cee8e5',
    },
  },
  revokable: false,
  theme: 'edgeless',
  position: 'bottom-right',
  law: {
    countryCode: 'test',
    regionalLaw: false,
  },
};

export function initFirebase() {
  return {
    authDomain: environment.firebaseAuthDomain,
    apiKey: environment.firebaseApiKey,
  };
}

@NgModule({
  declarations: [AppComponent, IsNavigatingComponent],
  imports: [
    AppConfigModule,
    AngularFireModule,
    BrowserModule,
    BrowserAnimationsModule,
    CommonComponentsModule,
    AppRoutingModule,
    PublicModule,
    PrivateModule,
    NgcCookieConsentModule.forRoot(cookieConfig),
    MatProgressBarModule,
    LeafletModule,
  ],
  providers: [
    { provide: FIREBASE_OPTIONS, useFactory: initFirebase },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    Meta,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
