import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TablesModule } from 'submodules/frontend-framework/SUVO-BI-CLIENT-LIB/projects/suvo-bi-client-lib/src/lib/features/tables/tables.module';
import { HeaderComponent } from '../../../shared/components/header/header.component';
import { SharedModule } from '../../../shared/shared.module';
import { PermissionsModule } from '../permissions/permissions.module';
import { CprTableComponent } from './components/cprs-table/cpr-table.component';
import { CprRoutingModule } from './cpr-routing.module';
import { CprResolver } from './resolver/cpr.resolver';
import { CprEditScreenComponent } from './screens/edit/cpr-edit-screen.component';
import { CprTableScreenComponent } from './screens/table/cpr-table-screen.component';
import { CprsService } from './service/cprs.service';

@NgModule({
  declarations: [CprEditScreenComponent, CprTableComponent, CprTableScreenComponent],
  providers: [CprsService, CprResolver],
  imports: [
    CommonModule,
    SharedModule,
    TablesModule,
    CprRoutingModule,
    PermissionsModule,
    HeaderComponent,
  ],
})
export class CprsModule {}
